import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Layout from "../../container/layout";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { useNavigate, useParams } from "react-router-dom";
import { initgames, balance, luchGame } from "./action";
import { connect } from "react-redux";
import Link from "@material-ui/core/Link";
import { actions } from "../../redux/actions";
import Settings from "../../component/form/setting";
import { CountDownDaily } from "@nilevia/count-down-timer-react";
import { useTranslation } from "react-i18next";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import moment from "moment";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {},
  main: {
    textAlign: "center",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  gameItem: {
    position: "relative",
    width: "100%",
    minWidth: 120,
    maxWidth: 200,
    margin: 8,
    height: "auto",
    "& img": {
      width: "100%",
      padding: 8,
      [theme.breakpoints.down("sm")]: {
        maxHeight: "230px !important",
      },
    },
    "&:hover img": {
      filter: "grayscale(.10) contrast(1.1)",
      mixBlendMode: "exclusion",
      transition: "all .5s",
      // transform: "rotateY(1turn)",

      WebkitTransform: "scale(0.9)",
    },
    "&:hover button": {
      opacity: "1",
    },
  },
  gameLists: {
    display: "flex",
    flexWrap: "wrap",
    alignContent: "baseline",
    margin: "16px 16px 16px 0",
  },
  buttonLists: {
    cursor: "pointer",
    position: "absolute",
    alignItems: "center",
    borderRadius: "4px",
    display: "inline-flex",
    flex: "0 0 auto",
    zIndex: "200",
    top: "20%",
    left: "50%",
    justifyContent: "center",
    outline: 0,
    transform: "translate(-50%,-50%)",
    background: "linear-gradient(180deg,#ffdf46,#ffdc42 23.96%,#feeb52 49.48%,#fbc316 71.87%,#f69b09)",
    padding: "0.5rem 0",
    width: "70%",
    fontWeight: "700",
    fontSize: "18px",
    color: "#212121",
    // visibility: "hidden",
    opacity: "0",
    transition: "visibility .2s,opacity .2s linear",
    height: 36,
    minWidth: 64,
    "&:before": {
      backgroundColor: "currentColor",
      borderRadius: "inherit",
      bottom: "0",
      color: "inherit",
      content: '""',
      left: "0",
      opacity: "0",
      pointerEvents: "none",
      position: "absolute",
      right: "0",
      top: "0",
      transition: "opacity .2s cubic-bezier(.4,0,.6,1)",
    },
  },
  buttonSetting: {
    cursor: "pointer",
    position: "absolute",
    alignItems: "center",
    borderRadius: "4px",
    display: "inline-flex",
    flex: "0 0 auto",
    zIndex: "200",
    top: "calc(50% + 50px)",
    left: "50%",
    justifyContent: "center",
    outline: 0,
    transform: "translate(-50%,-50%)",
    background: "#e53935",
    padding: "0.5rem 0",
    width: "70%",
    fontWeight: "700",
    fontSize: "14px",
    color: "#fff",
    // visibility: "hidden",
    opacity: "0",
    transition: "visibility .2s,opacity .2s linear",
    height: 36,
    minWidth: 64,
    "&:before": {
      backgroundColor: "currentColor",
      borderRadius: "inherit",
      bottom: "0",
      color: "inherit",
      content: '""',
      left: "0",
      opacity: "0",
      pointerEvents: "none",
      position: "absolute",
      right: "0",
      top: "0",
      transition: "opacity .2s cubic-bezier(.4,0,.6,1)",
    },
  },
  gameName: {
    position: "absolute",
    bottom: "-7px",
    left: "50%",
    padding: "3px 0!important",
    borderBottomRightRadius: "10px",
    borderBottomLeftRadius: "10px",
    transform: "translate(-50%,-50%)",
    textAlign: "center",
    zIndex: "3",
    backgroundColor: "rgba(0,0,0,.7)",
    whiteSpace: "nowrap",
    width: "93%",
    overflow: "hidden",
    textOverflow: "ellipsis",
    color: "#fff",
  },
}));

const Games = ({ games, ...props }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(true);
  const [warning, setWarning] = React.useState(false);

  const { t } = useTranslation();

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const init = async () => {
    props.dispatch({
      type: actions.setGames,
      loading: true,
    });

    const load = await Promise.all([initgames(), balance()]);
    if (load[0].result) {
      const { Balance, expired_at, username, currency, symbol } = load[1].payload;

      if (/^demotest_*/g.test(username)) {
        setWarning(true);
      }

      props.dispatch({
        type: actions.setGames,
        games: load[0].payload,
        userData: { ...props.userData, balance: Balance, expired_at, username, currency, symbol },
        loading: false,
      });
    } else {
      props.dispatch({
        type: actions.setGames,
        errorDialog: true,
        errorText: load[0].message,
        loading: false,
      });
    }
  };

  useEffect(() => {
    init();
  }, []);

  const settingOpenGame = (data) => {
    props.dispatch({
      type: actions.setting,
      giData: data,
      settingDialog: true,
    });
  };

  const handleLunch = async (game_id) => {
    // navigate("/games/" + game_id);

    props.dispatch({
      type: actions.setLoading,
      loading: true,
    });
    const url = await luchGame(game_id);
    if (url.result) {
      props.dispatch({
        type: actions.setLoading,
        loading: false,
      });

      // Object.assign(document.createElement("a"), {
      //   target: "_blank",
      //   rel: "noopener noreferrer",
      //   href: "/games/" + game_id,
      //   innerHTML: game_id,
      // }).click();

      // window.open("/games/" + game_id, "_blank");

      // const wopen = window.open(
      //   "/games/" + game_id,
      //   "_blank",
      //   "noopener,noreferrer"
      // );

      // if (wopen) {
      //   console.log("work");
      // } else {
      //   window.location.href = url.payload.url;
      // Object.assign(document.createElement("a"), {
      //   target: "_blank",
      //   rel: "noopener noreferrer",
      //   href: url.payload.url,
      //   innerHTML: game_id,
      // }).click();

      // url.payload.url
      // window.open(url.payload.url, "_blank", "noopener,noreferrer");
      // }
    } else {
      props.dispatch({
        type: actions.setLoading,
        loading: false,
      });
    }
  };

  const handleCloseWarning = () => {
    setWarning(false);
  };

  return (
    <Layout data={props.userData}>
      <Settings />

      <Dialog open={warning} onClose={handleCloseWarning} aria-labelledby="form-dialog-title" PaperProps={{ style: { background: "rgba(255,255,255,0.98)" } }}>
        <DialogContent style={{ minWidth: 320, padding: 0 }}>
          <Alert onClose={handleCloseWarning} icon={false} severity="error">
            <Typography variant="h5" gutterBottom>
              โปรดระวังมิจฉาชีพ!!
            </Typography>

            <Typography variant="h6" gutterBottom>
              ยูส {props.userData.username} สำหรับทดลองเท่านั้น <br />
              ห้ามมีการซื้อขาย
            </Typography>
          </Alert>
        </DialogContent>
      </Dialog>

      <Snackbar anchorOrigin={{ vertical: "top", horizontal: "center" }} open={open} autoHideDuration={30000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="info">
          {t("menu.usagetime")} <CountDownDaily className={classes.countdown} endDate={moment.utc(props.userData?.expired_at).format()} dayAffix="day" hourAffix="hrs" minutesAffix="min" secondAffix="s" />
        </Alert>
      </Snackbar>
      <Grid container>
        <div className={classes.gameLists}>
          {games.map((v, k) => (
            <Grid key={k} item xs={4} sm={3} md={3} lg={2}>
              <div key={k} className={classes.gameItem}>
                <img
                  src={v.image_url}
                  style={{
                    maxHeight: 260,
                    minHeight: 230,
                    width: "100%",
                    objectFit: "cover",
                  }}
                  alt=""
                />
                <button type="button" className={classes.buttonLists}>
                  <Link
                    href={`/games/${v.game_id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      textDecoration: "none",
                      display: "flex",
                      color: "#212121",
                      margin: 0,
                    }}
                    button
                  >
                    {t("menu.play")}
                  </Link>
                </button>

                {v.game_id !== 0 && (
                  <button type="button" onClick={() => settingOpenGame(v)} className={classes.buttonSetting}>
                    {t("menu.settinggame")}
                  </button>
                )}

                <span className={classes.gameName}>{v.game}</span>
              </div>
            </Grid>
          ))}
        </div>
      </Grid>
    </Layout>
  );
};

const mapStateToProps = (state, dis) => {
  return {
    games: state.app.games,
    userData: state.app.userData,
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(Games);
